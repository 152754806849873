import { $http } from "@/utils/https";
import { translation } from "@/helpers/translation";
import { globalLoader } from "@/helpers/variables";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import type { NavigationItem } from "@/types/site";
import type { VariablesData } from "@/types/api";

interface State {
	config: any | null;
	configLoading: boolean;
	locales: any | null;
	translations: any | null;
	variables: VariablesData | null;
	headerMenu: Array<NavigationItem>;
	salesHeaderMenu: Array<NavigationItem>;
}

const state: State = {
	config: null,
	configLoading: false,
	locales: null,
	translations: null,
	variables: null,
	headerMenu: [],
	salesHeaderMenu: [],
};

const getters = {
	variables: (state: State) => state.variables,
	locales: (state: State) => state.locales,
	translations: (state: State) => state.translations,
	config: (state: State) => state.config,
	headerMenu: (state: State) => state.headerMenu,
	salesHeaderMenu: (state: State) => state.salesHeaderMenu,
};

const actions = {
	GET_HEADER_MENU: async ({ commit }: ActionContext<State, RootState>) => {
		try {
			const response = await $http.get("v1/menus");
			commit("SET_HEADER_MENU", response.data.data);
		} catch (e) {
			throw e;
		}
	},
	GET_SALES_HEADER_MENU: async ({commit}: ActionContext<State, RootState>) => {
		try {
			const response = await $http.get('v1/sales/products');
			commit('SET_SALES_HEADER_MENU', response.data.data);
		} catch (e) {
			throw e;
		}
	},
	GET_VARIABLES: async ({ commit }: ActionContext<State, RootState>) => {
		try {
			const response = await $http.get("v1/variables");
			commit("SET_VARIABLES", response.data.data);
		} catch (e) {
			throw e;
		}
	},
	GET_TRANSLATIONS: async ({ commit }: ActionContext<State, RootState>) => {
		globalLoader(true);
		try {
			const response = await $http.get("v1/translations");
			let result = Object.assign(response.data, translation);
			commit("SET_TRANSLATIONS", result);
			return result;
		} catch (e) {
			throw e;
		} finally {
			globalLoader(false);
		}
	},
};

const mutations = {
	SET_HEADER_MENU(state: State, list: Array<NavigationItem>) {
		state.headerMenu = list;
	},
	SET_SALES_HEADER_MENU(state: State, list: any) {
		state.salesHeaderMenu = list;
	},
	SET_VARIABLES(state: State, status: any) {
		state.variables = status;
	},
	SET_TRANSLATIONS(state: State, status: any) {
		state.translations = status;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
